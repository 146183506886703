import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { mapActions, mapGetters } from 'vuex';
import PageTitleWithCountComponent from '@/core/components/common/header/PageTitleWithCountComponent.vue';
import SearchInputComponent from '@/core/components/common/header/SearchInputComponent.vue';
import Loader from '@/core/components/ui/Loader.vue';
import { PAGE_LIMIT } from '@/core/constants';
import { ProjectJobStatusEnum } from '@/core/packages/shared-library';
import DisplayViewComponent from '@/modules/project-search/components/DisplayViewComponent.vue';
import NoProjectsComponent from '@/modules/projects/components/NoProjectsComponent.vue';
import ProjectViewComponent from '@/modules/property-details-children/components/ProjectViewComponent.vue';
import { PROPERTIES_PROJECT_POST_STORE } from '@/store/modules/project-post';
// eslint-disable-next-line import/no-cycle
import { PROJECTS_STORE } from '@/store/modules/projects';
import { USERS_STORE } from '@/store/modules/users';
import ProjectFilterComponent from '../components/ProjectFilterComponent.vue';
export default {
    components: {
        ProjectFilterComponent,
        DisplayViewComponent,
        ProjectViewComponent,
        NoProjectsComponent,
        PageTitleWithCountComponent,
        SearchInputComponent,
        Loader
    },
    data() {
        return {
            totalProjects: 0,
            currentFilters: {
                page: 1,
                limit: PAGE_LIMIT,
                projectJobStatusIds: [],
            }
        };
    },
    mounted() {
        this.initialize();
    },
    computed: {
        ...mapGetters(['isLoading']),
        ...mapGetters(USERS_STORE, ['hasMainLayoutInitialized']),
        ...mapGetters(PROJECTS_STORE, ['projects']),
    },
    methods: {
        ...mapActions(['setIsLoading']),
        ...mapActions(PROJECTS_STORE, ['getProjectPageList', 'setProjects']),
        ...mapActions(PROPERTIES_PROJECT_POST_STORE, [
            'getProjectMembersPokedState',
            'updateProjectMember'
        ]),
        onSelectJobStatus(menu) {
            const { status } = menu;
            const { currentFilters } = this;
            currentFilters.projectJobStatusIds = [];
            if (status) {
                // if it is not started
                if (status === ProjectJobStatusEnum.QUOTE_CONFIRMED) {
                    const notStartedList = [
                        ProjectJobStatusEnum.AWAITING_QUOTES,
                        ProjectJobStatusEnum.QUOTES_RECEIVED,
                        ProjectJobStatusEnum.QUOTE_ACCEPTED
                    ];
                    currentFilters.projectJobStatusIds = currentFilters.projectJobStatusIds.concat(notStartedList);
                }
                currentFilters.projectJobStatusIds.push(status);
            }
            this.loadProjects(currentFilters);
        },
        async initialize() {
            await this.loadProjects({});
            await this.checkIfHasPokedMemberInvitation();
        },
        async loadProjects(filters) {
            await this.getProjectPageList(filters).then((response) => {
                const { data, totalCount } = response;
                this.totalProjects = totalCount;
                this.setProjects(data);
            }).finally(() => {
                this.setIsLoading(false);
            });
        },
        async checkIfHasPokedMemberInvitation() {
            const projectId = 1; // used only for api state validity
            const requestPromises = [];
            await this.getProjectMembersPokedState(projectId).then(async (projectMembers) => {
                if (projectMembers && projectMembers.length) {
                    projectMembers.forEach((projectMember) => {
                        const payload = {
                            projectId,
                            projectMemberId: projectMember.id,
                            formData: { memberPermissionId: projectMember.memberPermissionId, token: projectMember.token }
                        };
                        requestPromises.push(this.updateProjectMember(payload));
                    });
                }
            });
            if (requestPromises.length) {
                await Promise.allSettled(requestPromises);
                await this.loadProjects({});
            }
        }
    },
    setup() {
        const selectedMenu = ref('All');
        const viewType = ref('list');
        const router = useRouter();
        function setView(event) {
            viewType.value = event;
        }
        function routeToAddProjectForm() {
            router.push({
                name: 'select-property',
            });
        }
        return {
            selectedMenu,
            viewType,
            setView,
            routeToAddProjectForm
        };
    },
};
